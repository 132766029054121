<template>
  <el-card class="know-list">
    <div slot="header" class="clearfix" style="display: flex;">
      <el-image :src="require('@/assets/images/problemList/标签@2x.png')" style="width: 24px; height: 24px"></el-image>
      <span style="color: #00957e">答题统计</span>
    </div>
    <form-create v-model="where" :components="[
              {name:'form-item',props:{prop:'member_name',label:'用户昵称',width:'160px'}},
              {name:'form-item',props:{prop:'answer_date',label:'答题时间',type:'daterange',width:'240px',startField:false}},
              {name:'form-item',props:{prop:'grade_range',label:'分数范围' , type:'number'},slots:{
                prepend:{name:'form-item',props:{prop:'grade_range_type',defaultValue:'>=',itemType:0,clearable:false,type:'select',data:['=','>','>=','<','<=',]},style:{width:'80px',}}
              },style:{width:'320px',}},
          ]" inline :labelWidth="'68px'">
      <el-form-item class="operate" slot="after">
        <el-button type="primary" size="small" @click="getCensusInfo(where) && $refs.table.reset(where)">查询</el-button>
        <el-button type="primary" size="small" @click="getCensusInfo(where = {}) && $refs.table.reset(where)">重置</el-button>
      </el-form-item>
    </form-create>
    <slot name="census" v-bind="censusInfo">
      <el-row style="margin-bottom: 20px;">
        <el-col :span="3">
          总分:{{censusInfo.total_grade}}
        </el-col>
        <el-col :span="3" :offset="1">
          平均分:{{censusInfo.avg_grade}}
        </el-col>
        <el-col :span="3" :offset="1">
          及格人数:{{censusInfo.pass_num}}
        </el-col>
        <el-col :span="3" :offset="1">
          不及格人数:{{censusInfo.no_pass_num}}
        </el-col>
      </el-row>
    </slot>
    <com-table
        ref="table"
        :data="{
          url:tableUrl
        }"
        :columns="columns"
        :where="where"
    >
      <template slot-scope="scope" :slot="slotName" v-for="slotName in Object.keys($scopedSlots).filter(s=>['census','action'].indexOf(s) === -1)">
        <slot :name="slotName" v-bind="scope"> </slot>
      </template>
      <template slot="action" slot-scope="scope">
        <slot name="action" v-bind="scope">
          <el-button @click="$handleRoute({id:scope.row.question_id,log_id:scope.row.log_id},'/topic/topicRecord')" type="text" size="small">详情</el-button>
        </slot>
      </template>
    </com-table>
  </el-card>
</template>

<script>
export default {
  name:'questionCensus',
  props:{
    // columns:{
    //   type:Array,
    //   default(){
    //     return [
    //       {prop:'log_id',label:'ID',width:'80' , align:'center'},
    //       {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',template:(scope)=>{
    //           return '<font style="color:red;">'+(scope.row.type_str||'')+'</font> - <font style="color:green;">'+(scope.row.create_user_name || '')+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
    //         },minWidth:'320px' , align:'left'},
    //       {prop:'member_name',label:'用户',width:'140' , align:'center'},
    //       {prop:'grade',label:'得分',width:'80' , align:'center'},
    //       {prop:'total_grade',label:'总分',width:'80' , align:'center'},
    //       // {prop:'answer_time',label:'答题时间',width:'80' , align:'center'},
    //       {prop:'last_commit_date',label:'提交时间',width:'160' , align:'center'},
    //       {fixed:'right',label:'操作',width:'80' , slot:'action' , align:'center'},
    //     ]
    //   }
    // },
    censusUrl: {
      type: String,
      default() {
        return '/xapi/question.question/censusInfo'
      },
    },
   tableUrl: {
     type: String,
     default() {
       return '/xapi/question.question/listInfo'
     }
   }
  },
  data(){
    return {
      where:{},
      censusInfo:{},
      columns:[
          {prop:'log_id',label:'ID',width:'80' , align:'center'},
          {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 作业名称</span>',template:(scope)=>{
              return '<font style="color:red;">'+(scope.row.type_str||'')+'</font> - <font style="color:green;">'+(scope.row.create_user_name || '')+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
            },minWidth:'320px' , align:'center'},
          {prop:'member_name',label:'用户',width:'140' , align:'center'},
          {prop:'grade',label:'得分',width:'80' , align:'center'},
          {prop:'total_grade',label:'总分',width:'80' , align:'center'},
          // {prop:'answer_time',label:'答题时间',width:'80' , align:'center'},
          {prop:'last_commit_date',label:'提交时间',width:'160' , align:'center'},
          {fixed:'right',label:'操作',width:'80' , slot:'action' , align:'center'},
        ]
    }
  },
  methods:{
    routeUpdate(){
      this.getCensusInfo(this.where);
    },
    getList(where){
      where = where || this.where;
      this.getCensusInfo(where);
      if(this.$refs.table){
        this.$refs.table.reset(where)
      }
    },
    getCensusInfo(where){
      return this.$http.post(this.censusUrl,{...this.$route.params,...where}).then(({data})=>{
        this.censusInfo = data || {};
      })
    }
  }
}
</script>

<style scoped>

</style>