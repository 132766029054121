var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"know-list"},[_c('div',{staticClass:"clearfix",staticStyle:{"display":"flex"},attrs:{"slot":"header"},slot:"header"},[_c('el-image',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require('@/assets/images/problemList/标签@2x.png')}}),_c('span',{staticStyle:{"color":"#00957e"}},[_vm._v("答题统计")])],1),_c('form-create',{attrs:{"components":[
            {name:'form-item',props:{prop:'member_name',label:'用户昵称',width:'160px'}},
            {name:'form-item',props:{prop:'answer_date',label:'答题时间',type:'daterange',width:'240px',startField:false}},
            {name:'form-item',props:{prop:'grade_range',label:'分数范围' , type:'number'},slots:{
              prepend:{name:'form-item',props:{prop:'grade_range_type',defaultValue:'>=',itemType:0,clearable:false,type:'select',data:['=','>','>=','<','<=',]},style:{width:'80px',}}
            },style:{width:'320px',}},
        ],"inline":"","labelWidth":'68px'},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.getCensusInfo(_vm.where) && _vm.$refs.table.reset(_vm.where)}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.getCensusInfo(_vm.where = {}) && _vm.$refs.table.reset(_vm.where)}}},[_vm._v("重置")])],1)],1),_vm._t("census",function(){return [_c('el-row',{staticStyle:{"margin-bottom":"20px"}},[_c('el-col',{attrs:{"span":3}},[_vm._v(" 总分:"+_vm._s(_vm.censusInfo.total_grade)+" ")]),_c('el-col',{attrs:{"span":3,"offset":1}},[_vm._v(" 平均分:"+_vm._s(_vm.censusInfo.avg_grade)+" ")]),_c('el-col',{attrs:{"span":3,"offset":1}},[_vm._v(" 及格人数:"+_vm._s(_vm.censusInfo.pass_num)+" ")]),_c('el-col',{attrs:{"span":3,"offset":1}},[_vm._v(" 不及格人数:"+_vm._s(_vm.censusInfo.no_pass_num)+" ")])],1)]},null,_vm.censusInfo),_c('com-table',{ref:"table",attrs:{"data":{
        url:_vm.tableUrl
      },"columns":_vm.columns,"where":_vm.where},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter(s=>['census','action'].indexOf(s) === -1)),function(slotName){return {key:slotName,fn:function(scope){return [_vm._t(slotName,null,null,scope)]}}}),{key:"action",fn:function(scope){return [_vm._t("action",function(){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$handleRoute({id:scope.row.question_id,log_id:scope.row.log_id},'/topic/topicRecord')}}},[_vm._v("详情")])]},null,scope)]}}],null,true)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }